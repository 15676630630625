import React, { useEffect } from "react";
import { LoggerModule } from "@vlinder-web/logger-module-react";

import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";

import { actions } from "./actions";
import { MACHINE_CONFIG } from "./config";
import { services } from "./services";

export const landingPageContext = React.createContext({});
export let landingPageMachine: any;

const MODULE_NAME = "machines.pageContent";
const log: any = LoggerModule?.getInstance();

export const useSMLandingPage = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  landingPageMachine = createMachine(_machineConfig, { services, actions });
  const [state, send] = useMachine(landingPageMachine);

  useEffect(() => {
    send({ type: "START" });
  }, []);

  return {
    landingPageMachine,
    landingPageState: state,
    landingPageSend: send,
  };
};
